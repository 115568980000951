import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  /**
   * Trans form
   *
   * @param url
   * @returns
   */
  public transform(url: any): unknown {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
}
