import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from 'app/common/helper';
import { ReferencePositionTimetableColumnEnum } from 'app/config/constants';

@Pipe({
  name: 'timetableOperationDate'
})
export class TimetableOperationDatePipe implements PipeTransform {
  /**
   * Transform
   * @param value
   * @param indexColumn
   * @returns
   */
  transform(value: any, indexColumn?: any) {
    if (indexColumn != ReferencePositionTimetableColumnEnum.TIME) {
      return value;
    }
    return Helper.convertTimeTimetableSchedule(value);
  }
}
