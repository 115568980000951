import { formatNumber } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  KeyValueDiffers,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { Helper as Helper2 } from 'app/layouts/helper';
import { Common } from 'app/model/entity/common';
import { IHash, OptionFilter, SortFilterObject } from 'app/model/entity/sort-filter-object';
import {
  SaveUserManagerStateAction,
  SaveSortFilterUserManagerStateAction
} from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { SortFilterUserManagerService } from 'app/service/sort-filter-user-manager.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ALL_SOFTWARES, Constant, FIELD_COMPONENT, MODULE_NAME, RoleIdEnum, RoleNameEnum, SortTypeEnum } from '../../config/constants';
import { User } from '../../core/user/user.model';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { DialogUserManagerComponent } from '../../dialog/dialog-user-manager/dialog-user-manager.component';
import { Privilege } from '../../model/entity/privilege';
import { DialogService } from '../../service/dialog.service';
import { MenuActionService } from '../../service/menu-action.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss']
})
/**
 * Component class for User
 */
export class UserManagerComponent implements OnInit, OnDestroy {
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('listUserElement', { static: false })
  listUserElement: ElementRef;
  /**
   * selected user
   */
  selectedUser: User;
  /**
   * list of users
   */
  listUser: User[];
  /**
   * list of action subscriptions
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * list of available privileges
   */
  privileges: Array<Privilege>;
  /**
   * true if user list is showed, false if privilege list is showed
   */
  isShowUserList: boolean;

  arrayDiffer: any;

  isChangedData: boolean = false;

  privilegesOld: Array<Privilege>;

  /**
   * constants
   */
  private readonly ROOT_USER = 'root';
  private readonly MINIMUM_PRIVILEGE = 1;
  private readonly ROLE = 'role';

  private userCurrent: any;

  /**
   * header column show on display
   */
  headerColumns: any = [];

  /**
   * header column original
   */
  headerColumnsOriginal: any = [
    { headerName: this.translateService.instant('user-manager.no'), property: 'no', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('user-manager.user-id'), property: 'userId', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('user-manager.name'), property: 'fullName', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('user-manager.role'), property: 'role', isSortBy: '', isFilterBy: '' }
  ];

  /**
   * digit Info
   */
  private readonly DIGIT_INFO = '3.0';
  private readonly MAX_USER = 99;

  /**
   * sort filter variable
   */
  public isSortFilter: boolean;
  public isCheckAllOptionFilter: boolean;
  public listUserDisplay: Array<any> = new Array<any>();
  public columnSortFiltering: string;
  public isShowPopUpSortFilter: boolean;
  public lastColumnFilter: string;
  public listFilterDisplay: Array<OptionFilter>;
  public listFilterDisplayOrigin: Array<OptionFilter>;
  public isFilter: boolean;
  public isClear: boolean;
  public listCurrentFilter: IHash = {};
  public listSorted: any = [];
  private readonly LAST_FILTER = 'lastFilter';
  private readonly IS_FILTER = 'isFilter';
  /**
   * common object
   */
  private commonObject: Common;
  /**
   * check add edit user
   */
  private checkAddEditUser: boolean;
  /**
   * state of component
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    listUser: User[];
    privileges: Privilege[];
    privilegesOld: Privilege[];
    selectedUser: User;
    isShowUserList: boolean;
    isChangedData: boolean;
    listUserDisplay: User[];
    isSortFilter: boolean;
    isShowPopUpSortFilter: boolean;
    isCheckAllOptionFilter: boolean;
    columnSortFiltering: string;
    lastColumnFilter: string;
    listFilterDisplay: Array<OptionFilter>;
    listFilterDisplayOrigin: Array<OptionFilter>;
    isFilter: boolean;
    isClear: boolean;
    listCurrentFilter: IHash;
    listSorted: [];
    headerColumns: [];
    headerColumnsOriginal: [];
  };

  /**
   * Sort filter object
   */
  private sortFilterObject: SortFilterObject;

  /**
   * constructor
   * @param userService service for handling user data
   * @param dialogService service for showing dialogs
   * @param actionService service for handling menu action
   */
  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private kvDiffers: KeyValueDiffers,
    private actionService: MenuActionService,
    private toast: ToastrService,
    public readonly store: Store<AppState>,
    private dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    @Inject(LOCALE_ID) public locale: string,
    public translateService: TranslateService,
    private sortFilterService: SortFilterUserManagerService
  ) {
    this.subscriptions.push(
      this.actionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.UserManagerComponent] && this.isShowUserList) {
          this.addUser();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.UserManagerComponent] && this.isShowUserList) {
          this.editUserDetail(this.selectedUser, true);
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.UserManagerComponent] && this.isShowUserList) {
          this.deleteUser();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.UserManagerComponent] && !this.isShowUserList) {
          this.savePrivileges();
        }
      })
    );

    // sort filter
    this.subscriptions.push(
      this.actionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.UserManagerComponent] && this.isShowUserList) {
          this.sortFilter();
        }
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multiple language column header
        this.multiLanguageHeader();
        this.changeLanguageUserRole(this.listUser);
      })
    );

    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.userManagerState?.stateOfComponent.isChangeLayout,
            listUser: componentState.userManagerState?.stateOfComponent.listUser,
            privileges: componentState.userManagerState?.stateOfComponent.privileges,
            privilegesOld: componentState.userManagerState?.stateOfComponent.privilegesOld,
            selectedUser: componentState.userManagerState?.stateOfComponent.selectedUser,
            isShowUserList: componentState.userManagerState?.stateOfComponent.isShowUserList,
            isChangedData: componentState.userManagerState?.stateOfComponent.isChangedData,
            listUserDisplay: componentState.userManagerState?.stateOfComponent.listUserDisplay,
            isSortFilter: componentState.userManagerState?.stateOfComponent.isSortFilter,
            isShowPopUpSortFilter: componentState.userManagerState?.stateOfComponent.isShowPopUpSortFilter,
            isCheckAllOptionFilter: componentState.userManagerState?.stateOfComponent.isCheckAllOptionFilter,
            columnSortFiltering: componentState.userManagerState?.stateOfComponent.columnSortFiltering,
            lastColumnFilter: componentState.userManagerState?.stateOfComponent.lastColumnFilter,
            listFilterDisplay: componentState.userManagerState?.stateOfComponent.listFilterDisplay,
            listFilterDisplayOrigin: componentState.userManagerState?.stateOfComponent.listFilterDisplayOrigin,
            isFilter: componentState.userManagerState?.stateOfComponent.isFilter,
            isClear: componentState.userManagerState?.stateOfComponent.isClear,
            listCurrentFilter: componentState.userManagerState?.stateOfComponent.listCurrentFilter,
            listSorted: componentState.userManagerState?.stateOfComponent.listSorted,
            headerColumns: componentState.userManagerState?.stateOfComponent.headerColumns,
            headerColumnsOriginal: componentState.userManagerState?.stateOfComponent.headerColumnsOriginal
          };
        })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.userCurrent = componentState?.mainState?.stateOfComponent?.common?.user;
        })
    );
    this.arrayDiffer = this.kvDiffers.find({}).create();
    this.commonObject = commonService.getCommonObject();
    this.sortFilterObject = sortFilterService.getSortFilterObject();
  }

  ngOnInit(): void {
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    this.multiLanguageHeader();
    // get sort filter conditions
    this.getAllSortFilterConditions();
    if (!this.stateOfComponent?.isChangeLayout) {
      this.isShowUserList = this.commonObject?.isShowUserList === undefined ? true : this.commonObject.isShowUserList;
      this.dataService.sendData([Constant.IS_SHOW_USER_LIST, this.isShowUserList]);
      this.getListUser();
      this.getAllPrivileges();
    } else {
      this.handleAfterChangeLayout();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveUserManagerStateAction({
        isChangeLayout: true,
        listUser: this.listUser,
        privileges: this.privileges,
        privilegesOld: this.privilegesOld,
        selectedUser: this.selectedUser,
        isShowUserList: this.isShowUserList,
        isChangedData: this.isChangedData,
        listUserDisplay: this.listUserDisplay,
        isSortFilter: this.isSortFilter,
        isShowPopUpSortFilter: this.isShowPopUpSortFilter,
        isCheckAllOptionFilter: this.isCheckAllOptionFilter,
        columnSortFiltering: this.columnSortFiltering,
        lastColumnFilter: this.lastColumnFilter,
        listFilterDisplay: this.listFilterDisplay,
        listFilterDisplayOrigin: this.listFilterDisplayOrigin,
        isFilter: this.isFilter,
        isClear: this.isClear,
        listCurrentFilter: this.listCurrentFilter,
        listSorted: this.listSorted,
        headerColumns: this.headerColumns,
        headerColumnsOriginal: this.headerColumnsOriginal
      })
    );
    delete this.commonObject.isShowUserList;
  }

  public ngDoCheck(): void {
    let empListChanges = this.arrayDiffer.diff(this.privileges);
    if (!empListChanges) {
      this.isChangedData = this.checkAddEditUser ?? !this.compareDiff(this.privileges, this.privilegesOld);
    }
  }

  /**
   * compare diff
   * @param userFirst
   * @param userSecond
   * @returns
   */
  private compareDiff(userFirst: any, userSecond: any): any {
    if (!userFirst || !userSecond) {
      return true;
    }
    var tempFirst = _.cloneDeep(userFirst);
    var tempSecond = _.cloneDeep(userSecond);
    [tempFirst, tempSecond].forEach(temp => {
      if (temp) {
        delete temp.id;
        delete temp.moduleName;
        delete temp.name;
      }
    });
    return _.isEqual(tempFirst, tempSecond);
  }

  /**
   * handle after change layout
   */
  private handleAfterChangeLayout(): void {
    this.listUser = this.stateOfComponent?.listUser;
    this.privileges = this.stateOfComponent?.privileges;
    this.privilegesOld = this.stateOfComponent?.privilegesOld;
    this.selectedUser = this.stateOfComponent?.selectedUser;
    this.isShowUserList = this.stateOfComponent?.isShowUserList;
    this.isChangedData = this.stateOfComponent?.isChangedData;
    this.listUserDisplay = this.stateOfComponent?.listUserDisplay;
    this.isSortFilter = this.stateOfComponent?.isSortFilter;
    this.isShowPopUpSortFilter = this.stateOfComponent?.isShowPopUpSortFilter;
    this.isCheckAllOptionFilter = this.stateOfComponent?.isCheckAllOptionFilter;
    this.columnSortFiltering = this.stateOfComponent?.columnSortFiltering;
    this.lastColumnFilter = this.stateOfComponent?.lastColumnFilter;
    this.listFilterDisplay = this.stateOfComponent?.listFilterDisplay;
    this.listFilterDisplayOrigin = this.stateOfComponent?.listFilterDisplayOrigin;
    this.isFilter = this.stateOfComponent?.isFilter;
    this.isClear = this.stateOfComponent?.isClear;
    this.listCurrentFilter = this.stateOfComponent?.listCurrentFilter;
    this.listSorted = this.stateOfComponent?.listSorted;
    this.headerColumns = this.stateOfComponent.headerColumns;
    this.headerColumnsOriginal = this.stateOfComponent.headerColumnsOriginal;

    // store data
    this.commonObject.isShowUserList = this.isShowUserList;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dataService.sendData([Constant.IS_SHOW_USER_LIST, this.isShowUserList]);
  }

  /**
   * Add new user, display dialog Add User
   */
  public addUser(): void {
    if (this.listUser.length >= this.MAX_USER) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('user-manager.msg.title-error'),
          text: Helper.formatString(this.translateService.instant('user-manager.msg.max-user'), `${this.MAX_USER}`)
        }
      });
      return;
    }
    let user = new User(
      undefined,
      '',
      '',
      null,
      true,
      'en',
      null,
      '',
      new Date(),
      '',
      new Date(),
      '',
      RoleNameEnum.USER,
      null,
      RoleIdEnum.USER
    );
    this.editUserDetail(user, false);
  }

  /**
   * Select user
   * @param user
   */
  public selectUser(user: User): void {
    this.selectedUser = user;
    this.dataService.sendData([Constant.HAS_NOT_USER_EXISTS, this.listUserDisplay?.length == 0]);
    this.dataService.sendData([Constant.IS_USER_ROOT, this.selectedUser?.userId == this.ROOT_USER]);
  }

  /**
   * Get list of users to display
   */
  private getListUser(): void {
    this.userService.getUsers().subscribe(
      data => {
        this.listUser = data;
        this.listUser.forEach((user, index) => {
          user.no = this.getNoByFormatNumber(index);
        });
        this.listUserDisplay = [...this.listUser];
        this.changeLanguageUserRole(this.listUser);
        if (!this.isSortFilter) {
          this.selectUser(this.listUser[0]);
          return;
        }
        // case filter
        if (!_.isEmpty(this.listCurrentFilter)) {
          this.filterUserFirstTime();
        }
        // case sort
        if (this.listSorted[Constant.SORT_COLUMN_INDEX]) {
          this.listUserDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        }
        this.selectUser(this.listUserDisplay[Constant.FIRST_ELEMENT_INDEX]);
      },
      error => this.handleErrorFromApiOrNetWork(error)
    );
  }

  /**
   * get value No. by number format
   * @param index
   * @returns
   */
  private getNoByFormatNumber(index: number): any {
    return formatNumber(index + 1, this.locale, this.DIGIT_INFO);
  }

  /**
   * Edit user detail in case of editing or adding
   * @param {User} currentUser selected user
   * @param isEdit
   */
  private editUserDetail(currentUser: User, isEdit: boolean): void {
    if (isEdit && (!this.selectedUser || this.selectedUser?.userId == this.ROOT_USER)) {
      return;
    }
    this.isChangedData = true;
    this.checkAddEditUser = true;
    this.dialogService.showDialog(
      DialogUserManagerComponent,
      {
        data: {
          isEdit: isEdit,
          user: Object.assign({}, currentUser)
        }
      },
      result => {
        this.isChangedData = false;
        this.checkAddEditUser = false;
        if (!result) {
          return;
        }
        let userSaved = <User>result['user'];
        if (isEdit) {
          let isDuplicateUserId = result['isDuplicateUserId'];
          if (!isDuplicateUserId) {
            let indexUser = this.listUser.findIndex(user => user.id == this.selectedUser.id);
            userSaved.role = userSaved.role;
            userSaved.no = this.getNoByFormatNumber(indexUser);
            let indexUserDisplay = this.listUserDisplay.findIndex(userDisplay => userDisplay.id == this.selectedUser.id);
            this.listUser[indexUser] = userSaved;
            this.listUserDisplay[indexUserDisplay] = userSaved;
            this.saveDataSuccess.emit(true);
            this.changeLanguageUserRole(this.listUser);
            this.selectUser(this.listUser[indexUser]);
            this.changeDetectorRef.detectChanges();
            this.scrollIntoViewSelected(this.listUserElement, indexUserDisplay);
          } else {
            if (userSaved.userId != this.commonObject.userIdString) {
              this.dialogService.showDialog(
                DialogMessageComponent,
                {
                  data: {
                    title: this.translateService.instant('dialog-message.error-title'),
                    text: this.translateService.instant('dialog-message.user-not-exists')
                  }
                },
                () => this.handleAfterDeleteUser()
              );
              return;
            }
            Helper2.handleErrorUserNotExists(this.dialogService, this.store, this.translateService);
            return;
          }
        } else {
          if (userSaved) {
            userSaved.roleId = userSaved[this.ROLE]['id'];
            userSaved.role = userSaved[this.ROLE]['name'];
            userSaved.no = this.getNoByFormatNumber(this.listUser.length);
            this.listUser.push(userSaved);
            this.listUserDisplay.push(userSaved);
            this.changeLanguageUserRole(this.listUser);
            this.selectUser(this.listUserDisplay[this.listUserDisplay.length - 1]);
            this.changeDetectorRef.detectChanges();
            this.scrollIntoViewSelected(this.listUserElement, this.listUserDisplay.length - 1);
            this.saveDataSuccess.emit(true);
          }
        }
      }
    );
  }

  /**
   * delete selected user
   */
  private deleteUser(): void {
    if (!this.selectedUser) {
      return;
    }
    if (this.selectedUser.userId == this.ROOT_USER) {
      return;
    }
    if (this.selectedUser.userId == this.commonObject.userIdString) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('user-manager.msg.title-error'),
          text: this.translateService.instant('user-manager.msg.delete-user-login')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('user-manager.msg.want-delete'), this.selectedUser.fullName),
          button1: this.translateService.instant('user-manager.btn-yes'),
          button2: this.translateService.instant('user-manager.btn-no')
        }
      },
      result => {
        if (result) {
          this.userService.deleteUser(this.selectedUser).subscribe(
            () => {
              this.handleAfterDeleteUser();
            },
            error => this.handleErrorFromApiOrNetWork(error)
          );
        }
      }
    );
  }

  /**
   * handle after delete user
   */
  private handleAfterDeleteUser(): void {
    this.listUser.splice(
      this.listUser.findIndex(user => user.id == this.selectedUser.id),
      1
    );
    this.listUserDisplay.splice(
      this.listUserDisplay.findIndex(userDisplay => userDisplay.id == this.selectedUser.id),
      1
    );
    this.listUser.forEach((user, index) => {
      user.no = this.getNoByFormatNumber(index);
      this.listUserDisplay.forEach(data => {
        if (data.id == user.id) {
          data.no = user.no;
        }
      });
    });
    this.selectUser(this.isSortFilter ? this.listUserDisplay[0] : this.listUser[0]);
    this.dataService.sendData([Constant.HAS_NOT_USER_EXISTS, this.listUserDisplay?.length == 0]);
  }

  /**
   * toggle manager privilege
   * @param index privilege index
   */
  public toggleManagerPrivilege(index: number): void {
    this.privileges[index].isManagerAllowed = !this.privileges[index].isManagerAllowed;
  }

  /**
   * toggle user privilege
   * @param index privilege index
   */
  public toggleUserPrivilege(index: number): void {
    this.privileges[index].isUserAllowed = !this.privileges[index].isUserAllowed;
  }

  /**
   * save privilege settings
   */
  private savePrivileges(): void {
    if (this.privileges.every(data => !data.isUserAllowed) || this.privileges.every(data => !data.isManagerAllowed)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('user-manager.msg.title-error'),
          text: Helper.formatString(this.translateService.instant('user-manager.msg.no-privilege-setting'), `${this.MINIMUM_PRIVILEGE}`)
        }
      });
      return;
    }
    this.userService.updatePrivilege(this.privileges).subscribe(
      () => {
        this.getAllPrivileges();
        this.toast.success(this.translateService.instant('user-manager.save-success'), '');
        this.saveDataSuccess.emit(true);
      },
      error => {
        this.handleErrorFromApiOrNetWork(error);
        this.saveDataSuccess.emit(false);
      }
    );
  }

  /**
   * change tab
   * @param value
   */
  public changeTab(value: string): void {
    if (value == 'LIST') {
      this.isShowUserList = true;
    } else {
      this.isShowUserList = false;
    }
    this.commonObject.isShowUserList = this.isShowUserList;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dataService.sendData([Constant.IS_SHOW_USER_LIST, this.isShowUserList]);
  }

  /**
   * scroll select row
   * @param elementRef
   * @param index
   */
  private scrollIntoViewSelected(elementRef: ElementRef, index: number): void {
    _.get(elementRef, `nativeElement.children[${index}]`, elementRef)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  /**
   * get all privileges
   */
  private getAllPrivileges(): void {
    this.userService.getAllPrivileges().subscribe(
      (privilegesData: Privilege[]) => {
        this.privileges = privilegesData.sort(function(privilege1, privilege2) {
          return ALL_SOFTWARES.indexOf(privilege1.moduleName) - ALL_SOFTWARES.indexOf(privilege2.moduleName);
        });
        this.privilegesOld = _.cloneDeep(this.privileges);
        this.isChangedData = false;
      },
      error => this.handleErrorFromApiOrNetWork(error)
    );
  }

  /**
   * Sort filter
   */
  public sortFilter(): void {
    this.isSortFilter = !this.isSortFilter;
    this.sortFilterObject.isSortFilter = this.isSortFilter;
    this.saveSortFilterStateAction();
    if (!this.isSortFilter) {
      this.resetSortFilter();
    }
    this.multiLanguageHeader();
  }

  /**
   * Reset sort filter
   */
  public resetSortFilter(): void {
    this.getListUser();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    // store data sort filter
    this.sortFilterObject.isFilter = this.isFilter;
    this.sortFilterObject.listSorted = this.listSorted;
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * show pop up sort filter
   * @param property name column
   * @param event
   */
  public showPopupSortFilter(property: string, event): void {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = property;
      this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
      this.fetchFilterData(property);
    }
    this.saveSortFilterStateAction();
  }

  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    let listUserGetOptionFilter = this.listUserDisplay.filter(data => !data[this.LAST_FILTER] || data[this.LAST_FILTER] === property);
    let listUserOptionFilter: User[] = this.getUniqueOption(listUserGetOptionFilter, property);
    if (property == this.ROLE) {
      this.changeLanguageUserRole(listUserOptionFilter);
    }
    // if not last column filter
    if (this.lastColumnFilter !== property) {
      this.listFilterDisplay = [];
      for (let i = 0; i < listUserOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: !listUserOptionFilter[i][this.IS_FILTER],
          name: listUserOptionFilter[i][property],
          roleId: property == this.ROLE ? listUserOptionFilter[i]['roleId'] : null
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
      // update if add
      listUserOptionFilter.forEach(user => {
        if (!this.listFilterDisplay.find(optionFilter => optionFilter.name === user[property])) {
          this.listFilterDisplay.push({
            isChecked: true,
            name: user[property],
            roleId: property == this.ROLE ? user['roleId'] : null
          });
        }
      });
      // remove old value
      this.listFilterDisplay?.forEach(option => {
        if (option.isChecked && !listUserOptionFilter.find(user => user[property] === option.name)) {
          this.listFilterDisplay = this.listFilterDisplay.filter(data => data.name !== option.name);
        }
      });
    }
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    if (property == this.ROLE) {
      this.changeLanguageListFilterDisplay();
    }
    // get list memorize checked
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin.every(filter => filter.isChecked);
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.sortFilterObject.listSorted = this.listSorted;
    this.listUserDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.resetColumnsSort();
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * Reset columns sort
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * sort multiple
   *
   * @param dataSort list properties and sort type sorted
   */
  private dynamicSortMultiple(dataSort: any): any {
    return function(object1: any, object2: any) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * show custom sort dialog and sort
   */
  public showCustomSort(): void {
    this.isShowPopUpSortFilter = false;
    let propertySorts = _.cloneDeep(this.headerColumns);
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }

        this.sortFilterObject.listSorted = this.listSorted;
        this.sortFilterObject.headerColumns = this.headerColumns;
        this.listUserDisplay = this.listUser?.filter(data => !data[this.LAST_FILTER]);
        this.listUserDisplay.sort(this.dynamicSortMultiple(result));
        this.selectUser(this.listUserDisplay[Constant.FIRST_ELEMENT_INDEX]);
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
      this.saveSortFilterStateAction();
    });
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    this.saveSortFilterStateAction();
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.isFilter = false;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });

    this.sortFilterObject.headerColumns = this.headerColumns;
    this.sortFilterObject.isFilter = this.isFilter;
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;

    if (property === this.lastColumnFilter) {
      this.isClear = true;
      this.sortFilterObject.isClear = this.isClear;
      this.listFilterDisplayOrigin.forEach(data => (data.isChecked = true));
      this.filterUser(property, false);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
      // set lastFilter is next column filter
      this.listUser?.forEach(element => {
        if (element[this.LAST_FILTER] === property) {
          element[this.IS_FILTER] = false;
          element[this.LAST_FILTER] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listUsersGetOptionFilter = this.listUser?.filter(
        data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
      );
      let listUsersOptionFilter = this.getUniqueOption(listUsersGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
      for (let i = 0; i < listUsersOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listUsersOptionFilter[i].lastFilter,
          name: listUsersOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let j = 0; j < this.listCurrentFilter[nextProperTyFilter]?.length; j++) {
          if (element.name === this.listCurrentFilter[nextProperTyFilter][j].name) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][j].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      this.saveSortFilterStateAction();
      this.controlCheckBoxCheckAllFilter();
      this.selectUser(this.listUserDisplay[Constant.FIRST_ELEMENT_INDEX]);
    }
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * filter user
   *
   * @param property
   * @param isFilterFirstTime
   */
  public filterUser(property: string, isFilterFirstTime: boolean): void {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      this.saveSortFilterStateAction();
      return;
    }
    this.isFilter = true;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;

    this.lastColumnFilter = property;
    let columnsFiltered = Object.keys(this.listCurrentFilter);
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) === -1) {
      delete this.listCurrentFilter[property];
      columnsFiltered = Object.keys(this.listCurrentFilter);
      this.lastColumnFilter = columnsFiltered[columnsFiltered.length - 1];
      this.isClear = false;
      this.isFilter = false;

      this.headerColumns.find(data => data.property === property).isFilterBy = Constant.EMPTY;
      this.sortFilterObject.isClear = this.isClear;
      // if filter a column was filtered
    } else {
      if (this.listCurrentFilter[property] && this.lastColumnFilter !== columnsFiltered[columnsFiltered.length - 1]) {
        let nextProperTyFilter = columnsFiltered[columnsFiltered.indexOf(property) + 1];
        this.listUser?.forEach(element => {
          if (element[this.LAST_FILTER] === property) {
            element[this.LAST_FILTER] = nextProperTyFilter;
          }
        });
        let listUsersGetOptionFilter = this.listUser?.filter(
          data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
        );
        let listUsersOptionFilter = this.getUniqueOption(listUsersGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
        for (let i = 0; i < listUsersOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listUsersOptionFilter[i].lastFilter,
            name: listUsersOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
    }
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.sortFilterObject.isFilter = this.isFilter;
    this.saveSortFilterStateAction();
    this.getCurrentFilter(this.listFilterDisplay, property);
    this.listUser?.filter(data => data[this.LAST_FILTER]).map(data => (data[Constant.IS_SELECTED] = false));
    this.listUserDisplay = this.listUser?.filter(data => !data[this.LAST_FILTER]);
    this.listUserDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.changeLanguageUserRole(this.listUser);
    this.isShowPopUpSortFilter = false;
    this.controlCheckBoxCheckAllFilter();
    if (this.listUserDisplay?.findIndex(data => data.id === this.selectedUser?.id) === -1) {
      this.selectedUser = undefined;
    }
    if (!isFilterFirstTime) {
      this.selectUser(this.listUserDisplay[Constant.FIRST_ELEMENT_INDEX]);
    }
  }

  /**
   * set lastFilter for user to filter or un filter
   *
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      let arr = this.listUser?.filter(data => data[property] == currentFilter[i].name);
      if (!currentFilter[i].isChecked) {
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * handle error from Api or Network
   */
  private handleErrorFromApiOrNetWork(error: any): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('user-manager.msg.title-error'),
        text:
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-user-manager.msg.error-network')
            : this.translateService.instant('dialog-message.an-error')
      }
    });
  }

  /**
   * multi language header
   */
  private multiLanguageHeader(): void {
    this.headerColumns.forEach((element, index) => {
      switch (index) {
        case SortFilterHeaderEnum.NO:
          element.headerName = this.translateService.instant('user-manager.no');
          break;
        case SortFilterHeaderEnum.USER_ID:
          element.headerName = this.translateService.instant('user-manager.user-id');
          break;
        case SortFilterHeaderEnum.NAME:
          element.headerName = this.translateService.instant('user-manager.name');
          break;
        case SortFilterHeaderEnum.ROLE:
          element.headerName = this.translateService.instant('user-manager.role');
          break;
        default:
          break;
      }
    });
  }

  /**
   * change language user role
   * @param elements
   */
  private changeLanguageUserRole(elements: any): void {
    if (!elements?.length) {
      return;
    }
    elements.forEach(user => {
      switch (user.roleId) {
        case RoleIdEnum.ADMINISTRATOR:
          user.role = this.translateService.instant('user-manager.role-admin');
          break;
        case RoleIdEnum.MANAGER:
          user.role = this.translateService.instant('user-manager.role-manager');
          break;
        case RoleIdEnum.USER:
          user.role = this.translateService.instant('user-manager.role-user');
          break;
        default:
          user.role = '';
          break;
      }
    });
  }

  /**
   * change language list filter display
   */
  private changeLanguageListFilterDisplay(): void {
    if (!this.listFilterDisplay?.length) {
      return;
    }
    this.listFilterDisplay.forEach(element => {
      switch (element.roleId) {
        case RoleIdEnum.ADMINISTRATOR:
          element.name = this.translateService.instant('user-manager.role-admin');
          break;
        case RoleIdEnum.MANAGER:
          element.name = this.translateService.instant('user-manager.role-manager');
          break;
        case RoleIdEnum.USER:
          element.name = this.translateService.instant('user-manager.role-user');
          break;
        default:
          element.name = '';
          break;
      }
    });
  }

  /**
   * Save sort filter state action
   */
  private saveSortFilterStateAction(): void {
    this.store.dispatch(
      new SaveSortFilterUserManagerStateAction({
        sortFilterUser: this.sortFilterObject
      })
    );
  }

  /**
   * Get all sort filter conditions
   */
  private getAllSortFilterConditions(): void {
    // get data from store
    this.isFilter = this.sortFilterObject?.isFilter;
    this.isSortFilter = this.sortFilterObject?.isSortFilter;
    this.isCheckAllOptionFilter = this.sortFilterObject?.isCheckAllOptionFilter;
    this.columnSortFiltering = this.sortFilterObject?.columnSortFiltering;
    this.listCurrentFilter = this.sortFilterObject?.listCurrentFilter;
    this.isClear = this.sortFilterObject?.isClear;
    this.listSorted = this.sortFilterObject?.listSorted;
    if (this.isSortFilter && (!_.isEmpty(this.listCurrentFilter) || this.listSorted[Constant.SORT_COLUMN_INDEX])) {
      this.headerColumns = this.sortFilterObject?.headerColumns;
    } else {
      this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    }
  }

  /**
   * Filter users when open user manager first time
   *
   * @returns
   */
  private filterUserFirstTime(): void {
    if (!this.listCurrentFilter) {
      this.selectUser(this.listUserDisplay[Constant.FIRST_ELEMENT_INDEX]);
      return;
    }
    let listCurrentFilter = _.cloneDeep(this.listCurrentFilter);
    let columnSortFilters = Object.keys(listCurrentFilter);
    columnSortFilters.forEach(columnSortFilter => {
      this.listFilterDisplay = _.cloneDeep(listCurrentFilter)[columnSortFilter];
      this.listFilterDisplayOrigin = this.listFilterDisplay;
      this.filterUser(columnSortFilter, true);
    });
  }
}

/**
 * Sort filter header enum
 */
export enum SortFilterHeaderEnum {
  NO,
  USER_ID,
  NAME,
  ROLE
}
